import styled from "styled-components/macro";

export const Container = styled.div`
  height: 7.43rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10rem 0 4rem;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);

  .brand {
    font-family: "Raleway";
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--hex-four);
    display: flex;
    align-items: center;
    & > img {
      @media (max-width: 550px) {
        display: none;
      }
    }
  }

  .burger-icon {
    display: none;
  }

  @media (max-width: 900px) {
    padding: 0 2rem;
    .burger-icon {
      display: block;
    }
  }

  @media (max-width: 600px) {
    padding: 0 1rem;
  }
`;
