import { useState } from "react";
import { useLocation } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import ToggleButton from "@mui/material/ToggleButton";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import logo from "../../assets/images/dark-red-black-logo-192.png";
import Sidebar from "../sidebar";
import { Container } from "./styles";

function Navbar() {
  const { pathname } = useLocation();
  const [showDrawer, setShowDrawer] = useState(false);
  const toggleDrawer = () => setShowDrawer(!showDrawer);
  return (
    <>
      <Container>
        <div className="brand">
          <img src={logo} alt="logo" />
          Partner App
        </div>
        {pathname !== "/login" && (
          <div className="burger-icon" onClick={toggleDrawer}>
            <ToggleButton value="justify">
              <FormatAlignJustifyIcon />
            </ToggleButton>
          </div>
        )}
      </Container>
      {pathname !== "/login" && (
        <Drawer anchor="left" open={showDrawer} onClose={toggleDrawer}>
          <Sidebar fromTop={true} />
        </Drawer>
      )}
    </>
  );
}

export default Navbar;
