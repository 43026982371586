import { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import AuthRoute from "./auth/AuthRoute";
import PrivateRoute from "./auth/PrivateRoute";
import Loader from "./components/loader";
import Navbar from "./components/navbar";
import Sidebar from "./components/sidebar";
import "./App.css";

const Home = lazy(() => import("./pages/home"));
const Login = lazy(() => import("./pages/login"));
const Timings = lazy(() => import("./pages/timings"));
const Bookings = lazy(() => import("./pages/bookings"));

function App() {
  return (
    <>
      <Navbar />
      <div className="app-content">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="main-content">
          <Suspense fallback={<Loader show={true} />}>
            <Switch>
              <Route path="/" exact component={Home} />
              <AuthRoute path="/login" exact>
                <Login />
              </AuthRoute>
              <PrivateRoute path="/timings" exact>
                <Timings />
              </PrivateRoute>
              <PrivateRoute path="/bookings" exact>
                <Bookings />
              </PrivateRoute>
            </Switch>
          </Suspense>
        </div>
      </div>
    </>
  );
}

export default App;
