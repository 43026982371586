import styled from "styled-components/macro";

export const Container = styled.div`
  width: 12.9em;
  background-color: var(--hex-five);
  font-size: 1.25rem;
  font-family: "Raleway";
  height: calc(100vh - ${(props) => (props.fromTop ? "0px" : "119px")});
  display: flex;
  flex-direction: column;

  .link.active {
    font-weight: bold;
    color: var(--hex-one);
  }

  .link-container {
    padding-top: 3.5em;
    padding-left: 1.45em;
  }
  .link {
    text-decoration: none;
    color: var(--hex-six);
  }
  .link:hover:not(.link.active) {
    color: var(--hex-six);
  }

  .logout {
    margin-top: auto;
    padding-left: 1.45em;
    padding-bottom: 1.5em;
    color: var(--hex-six);
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    font-size: 1.25rem;
    font-family: "Raleway";
    width: max-content;
  }
`;
