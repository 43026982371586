import { useLocation, NavLink } from "react-router-dom";
import { Container } from "./styles";

function Sidebar({ fromTop }) {
  const { pathname } = useLocation();
  if (pathname === "/login") return null;

  const handleLogout = () => {
    localStorage.removeItem("user");
    window.location.reload();
  };

  return (
    <Container fromTop={fromTop}>
      <div className="link-container">
        <NavLink to="/timings" className="link" activeClassName="active">
          Timings
        </NavLink>
      </div>
      <div className="link-container">
        <NavLink to="/bookings" className="link" activeClassName="active">
          Bookings
        </NavLink>
      </div>
      {/* <div className="link-container">
        <NavLink to="/studio-page" className="link" activeClassName="active">
          Studio Page
        </NavLink>
      </div>
      <div className="link-container">
        <NavLink
          to="/customer-database"
          className="link"
          activeClassName="active"
        >
          Customer Database
        </NavLink>
      </div> */}
      <button className="logout" onClick={handleLogout}>
        Logout
      </button>
    </Container>
  );
}

export default Sidebar;
